.contacts {
    padding: 0 0 6rem;
    background-color: #fff;
}

.contacts__container {
    display: flex;
    gap: 6rem;
    justify-content: space-between;

    @media (max-width: $mintablet) {
        flex-direction: column;
        gap: 4rem;
    }
}

.contacts__info {
    width: 100%;
    width: 30rem;
    flex-shrink: 0;
    padding: 2rem 0;
    font-size: 2rem;

    @media (max-width: $mintablet) {
        font-size: 1.8rem;
    }
}

.contacts__map {
    width: 100%;
    max-width: 90rem;
    height: 50rem;
    border-radius: 2rem;
    overflow: hidden;

    @media (max-width: $mintablet) {
        height: 30rem;
    }
}

.brone {
    display: flex;
    padding-top: 2rem;
}

.brone__btn {
    color: #000;
    text-transform: lowercase;
    font-weight: 500;
}

.contacts__title {
    margin-bottom: 3rem;
    font-size: 5rem;
    line-height: .9;

    @media (max-width: $tablet) {
        font-size: 3rem;
    }

    @media (max-width: $mintablet) {
        margin-bottom: 2rem;
    }

    & .beauty {
        font-family: $play;
    }
}

.contacts__list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.think {
    font-weight: 300;
}

.contacts__socials-list {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin-top: 2rem;
}