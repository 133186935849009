@font-face {
  font-family: "playfair";
  src: url("/fonts/playfair_display_italic.woff2") format("woff2"),
    url('/fonts/playfair_display_italic.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "suis";
  src: url("/fonts/suisseintl.woff2") format("woff2"),
    url('/fonts/suisseintl.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "suis";
  src: url("/fonts/suisseintl-book.woff2") format("woff2"),
    url('/fonts/suisseintl-book.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}