.header {
    @media (max-width: 1024px) {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 100;
    }

    background-color: #fff;
}

.header__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;

    @media (max-width: 1024px) {
        padding: .7rem 0;
    }
}

.header__nav-right,
.nav__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;

    @media (max-width: 1300px) {
        gap: 3rem;
    }
}

.nav__link {
    text-transform: uppercase;
    font-size: 2rem;

    @media (max-width: 1300px) {
        font-size: 1.6rem;
    }
}

.header__btn {
    padding: 1.6rem 5rem;
    text-transform: uppercase;
    font-size: 2rem;
    border-radius: 3rem;
    color: #fff;
    background-color: $yellow;
    line-height: 1;

    @media (max-width: 1300px) {
        padding: 1.5rem 3rem;
        font-size: 1.6rem;
    }
}

.header__nav-left,
.header__nav-right {
    @media (max-width: 1024px) {
        display: none;
    }
}

.header__logo-block {
    @media (max-width: 1024px) {
        max-width: 12rem;
    }
}

.header__burger {
    display: none;

    span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: 50%;

        background-color: #fff;

        &:nth-child(1) {
            top: 35%;
        }

        &:nth-child(2) {
            top: 50%;
        }

        &:nth-child(2) {
            top: 65%;
        }
    }

    @media (max-width: 1024px) {
        display: block;
        position: relative;
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        background-color: $yellow;
    }
}

.header__fixed-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: initial;
    right: 0;
    transform: translateX(100%);
    padding: 6rem 2rem;
    background-color: #fff;
    transition: all .3s;
}

.header__fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateX(100%);
    background-color: rgba(#000, .5);

    &.active {
        transform: translateX(0);

        & .header__fixed-inner {
            transform: translateX(0);
        }

    }
}

.header__burger-fixed-close {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    background-color: transparent;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 50%;
        height: 2px;
        background-color: #3e3e3e;
    }

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 50%;
        height: 2px;
        background-color: #3e3e3e;
    }
}

.header__fixed-inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.header__fixed-link {
    display: block;
    padding: .5rem;
    text-transform: uppercase;
    font-size: 1.6rem;
}