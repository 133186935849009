.plate {
    padding: 6rem 0;
    background-color: #fff;
}

.plate__title {
    margin-bottom: 6rem;
    font-size: 5.5rem;
    text-align: center;

    @media (max-width: $tablet) {
        font-size: 3rem;
    }

    @media (max-width: $mintablet) {
        margin-bottom: 3rem;
    }
}

.plate__beauty-text {
    font-family: $play;
}

.plate__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;

    @media (max-width: $tablet) {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    @media (max-width: $mintablet) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

.plate__item {
    position: relative;
    border-radius: 2rem;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(#000, .4);
        opacity: 0;
        transition: all .3s;
    }
}

.plate__desc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    color: #000;
    padding: 2rem;
    font-size: 3rem;
    font-family: $play;
    border-radius: 3rem;
    line-height: 1;
    border: 2px solid $yellow;
    opacity: 0;
    transition: all .3s;
    text-align: center;
    background-color: rgba(#fff, .8);

    @media (max-width: $tablet) {
        padding: 1.5rem;
        font-size: 2rem;
        opacity: 1;
        background-color: rgba(#fff, .9);
    }
}

.plate__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (any-hover:hover) {
    .plate__item:hover {
        &:before {
            opacity: 1;
        }

        & .plate__desc {
            opacity: 1;
        }
    }
}