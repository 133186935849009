.modal {
  position: fixed;
  top: 0;
  height: 100vh;
  left: -10000px;
  right: 10000px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding: 20px 30px 0px;
  background-color: rgba(rgb(21, 21, 21), .7);
  opacity: 0;
  transition: opacity 0.5s;
}

.modal_open {
  left: 0;
  right: 0;
  opacity: 1;
}

.modal__inner {
  position: relative;
  flex-basis: 60rem;
  box-shadow: 5px 5px 10px rgba(#000, .5);
  background-color: #fff;
  color: #000;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
}

.modal__wrapper {
  padding: 4rem 6rem 6rem;

  @media (max-width: 768px) {
    padding: 2rem 3rem 3rem;
  }
}

.modal__title {
  margin-bottom: 1rem;
  font-size: 3.2rem;
  font-family: $play;
  text-align: center;
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
}

.modal__input {
  width: 100%;
  margin-bottom: 1rem;
  height: 6rem;
  line-height: 6rem;
  padding: 0 30px;
  font-size: 1.6rem;
  border: 1px solid rgba(#000, .3);
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  transition: background-color 0.3s, border 0.3s;

  &:focus {
    background-color: #fff;
    color: #000;
    outline: none;
  }

  &::placeholder {
    color: rgba(#000, .5);
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
    }
  }
}

.modal__line {
  display: block;
  margin-top: 2rem;
}

.calculate__line {
  margin-top: 6rem;
  margin-bottom: 5rem;
}

.modal__input-date {
  position: relative;
  z-index: 5;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.air-datepicker-global-container {
  z-index: 1001;
}

.modal__input-date-icon {
  position: absolute;
  right: .5rem;
  top: 0;
  height: 80%;
  aspect-ratio: 1/1;
  background-image: url("/img/calendar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.modal__textarea {
  margin-bottom: 3rem;
  width: 100%;
  height: 10rem;
  padding: 20px 30px;
  resize: none;
  line-height: 1;

  &:focus {
    background-color: #fff;
    color: #000;
    outline: none;
  }

  &::placeholder {
    color: rgba(#000, .5);
    font-size: 1.6rem;
  }
}

.modal__desc {
  margin-bottom: 4rem;
  font-size: 1.4rem;
  text-align: center;
  font-weight: 300;
  line-height: 1;
}

.modal__privacy {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1;
  text-align: center;

  & a {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.modal__close {
  position: absolute;
  top: 0px;
  right: 0;
  width: 6rem;
  height: 6rem;
  border: none;
  background-color: transparent;
  cursor: pointer;

  @media (max-width: $mobile) {
    width: 3rem;
    height: 3rem;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 2px;
    background-color: rgba(#fff, .6);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.modal__send {
  margin-bottom: 2rem;
  width: 100%;
  padding: 1.6rem 5rem;
  text-transform: uppercase;
  font-size: 2rem;
  border-radius: 3rem;
  background-color: $yellow;
  line-height: 1;
  color: #000;
  text-transform: lowercase;
  font-weight: 500;

  @media (max-width: 1300px) {
    padding: 1.5rem 3rem;
    font-size: 1.6rem;
  }
}

.modal_ok {
  align-items: center;

  & .modal__inner {
    height: auto;
    overflow: hidden;
  }
}