.slogan {
    padding: 8rem 0 6rem;

    @media (max-width: $mobile) {
        padding: 4rem 0;
    }
}

.slogan__first-row,
.slogan__second-row {
    font-size: 5.5rem;
    text-align: center;
    line-height: 1;

    @media (max-width: $tablet) {
        font-size: 3rem;
    }

    @media (max-width: $mobile) {
        font-size: 2.8rem;
    }
}

.slogan__second-row {
    font-family: $play;
}