.line {
  position: relative;
  width: 100%;
  height: .4rem;
  background-color: rgba(#000, .05);
  border-radius: 1rem;
  border: none !important;
}

.line__start-num,
.line__end-num {
  position: absolute;
  top: calc(100% + .8rem);
  color: #000;
  font-size: 1.4rem;
}

.line__start-num {
  left: 0;
}

.line__end-num {
  right: 0;
}

.line__active-num {
  position: absolute;
  bottom: 2.4rem;
  left: calc(40 * 100 / 100) + 0%;
  transform: translateX(-100%);
  width: 4.8rem;
  height: 2.3rem;
  line-height: 2.3rem;
  border-radius: 10px;
  background-color: $yellow;
  font-size: 1.4rem;
  color: #000;
  text-align: center;
  font-weight: 500;

  @media (max-width: $mobile) {
    transform: translateX(-80%);
  }
}


.ui-slider-range {
  background-color: $yellow;
}

.ui-slider-handle {
  width: 2rem;
  height: 2rem;
  top: 50% !important;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: $yellow !important;
  border: none !important;
  outline: none !important;
}