//MEDIA
$desktop: 1440px;
$tablet: 1024px;
$mintablet: 768px;
$mobile: 420px;


//COLORS
$black: #000;
$white: #fff;
$yellow: #F6D85E;

//FONTS
$play: "playfair", Arial, sans-serif;
$suis: "suis", Arial, sans-serif;