.reviews {
    overflow: hidden;
    padding: 6rem 0 12rem;
    background-color: #fff;

    @media (max-width: $mintablet) {
        padding: 6rem 0 6rem;
    }
}

.reviews__slider {
    margin-top: 8rem;
    padding: 0 .2rem;

    @media (max-width: $mintablet) {
        width: calc(100% + 4rem);
        margin-top: 4rem;

        .owl-nav {
            display: none;
        }
    }
}

.reviews__minititle {
    margin-bottom: 6rem;
    font-size: 1.8rem;
    text-transform: uppercase;

    @media (max-width: $tablet) {
        font-size: 1.4rem;
    }

    @media (max-width: $mintablet) {
        margin-bottom: 1rem;
        text-align: center;
    }
}

.reviews__title {
    margin-bottom: 1rem;
    font-size: 5.5rem;
    text-align: center;

    @media (max-width: $tablet) {
        font-size: 3rem;
    }

    @media (max-width: $mintablet) {
        margin-bottom: .5rem;
    }
}

.reviews__beauty-text {
    font-family: $play;
}

.reviews .owl-carousel .owl-stage-outer {
    border-radius: 0;
}

.reviews-item {
    min-height: 30rem;
    padding: 3rem;
    border-radius: 3rem;
    border: 2px solid $yellow;

    @media (max-width: 1510px) {
        min-height: 35rem;
    }

    @media (max-width: 1260px) {
        min-height: 40rem;
    }

    @media (max-width: $mobile) {
        min-height: 35rem;
    }
}

.reviews__name {
    margin-bottom: 1.5rem;
    font-size: 3.5rem;
    font-family: $play;
    line-height: 1;

    @media (max-width: $tablet) {
        font-size: 2.5rem;
    }
}

.reviews__date {
    margin-bottom: 1.5rem;
    color: rgba(#000, .5);
    line-height: 1;
}

.reviews__text {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 1.2;

    @media (max-width: $tablet) {
        font-size: 1.6rem;
    }
}