.services {
    width: 100%;
    position: relative;
    margin-bottom: 4rem;

    @media (max-width: $mobile) {
        overflow: hidden;
    }

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: -5rem;
        bottom: 0rem;
        transform: translateX(-50%);
        width: 120%;
        background-image: url("/img/line.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 1440px) {
            top: -15rem;
            bottom: -10rem;
            width: 140%;
        }
    }
}

.services__title {
    margin-bottom: 6rem;
    font-size: 1.8rem;
    text-transform: uppercase;

    @media (max-width: $tablet) {
        font-size: 1.4rem;
    }

    @media (max-width: $mintablet) {
        margin-bottom: 3rem;
        text-align: center;
    }
}

.services__list {
    position: relative;
    height: 45rem;

    @media (max-width: $mintablet) {
        height: auto;
    }
}

.services__item {
    position: absolute;
    font-family: $play;
    font-size: 4rem;
    text-transform: lowercase;
    line-height: 1;

    @media (max-width: $tablet) {
        font-size: 3rem;
    }

    @media (max-width: $mintablet) {
        position: static;
        margin-bottom: 1rem;
        text-align: center;
        line-height: 1;
    }

    &:nth-child(1) {
        left: 4rem;
        top: 4rem;

        @media (max-width: $tablet) {
            top: 7rem;
        }

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 180%;
            aspect-ratio: 1/1;
            background-image: url("/img/Ellipse_33.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @media (max-width: $tablet) {
                width: 140%;
            }

            @media (max-width: $mintablet) {
                display: none;
            }
        }

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-60%, -45%);
            width: 180%;
            aspect-ratio: 1/1;
            background-image: url("/img/Ellipse_33.png");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            @media (max-width: $tablet) {
                width: 140%;
            }

            @media (max-width: $mintablet) {
                display: none;
            }
        }
    }

    &:nth-child(2) {
        left: 50%;
        top: 10rem;
    }

    &:nth-child(3) {
        left: initial;
        right: 3%;
        bottom: 18%;
    }

    &:nth-child(4) {
        left: 25%;
        bottom: 35%;
    }
}