/* Global */

:root {
  box-sizing: border-box;
  // --bg: rgb(255, 255, 0);
  // background-color: var(--bg);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.content-box-component {
  box-sizing: content-box;
}

html {
  height: 100%;
  width: 100%;
  font-size: 10px;
  line-height: 1.33;
  color: #1a212f;
  font-weight: normal;
  background-color: #fbf8f2;

  @media (min-width: 1921px) {
    font-size: .52vw;
  }
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: $suis;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.33;


}

button,
input {
  outline: none;
  border: none;
  cursor: pointer;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

label {
  cursor: pointer;
}

a {
  text-decoration: none;
}

// img {
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
//   flex-shrink: 0;
// }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  min-height: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: $tablet) {
    overflow: initial;
  }
}

.page {
  flex-grow: 1;
}

.container {
  margin: 0 auto;
  outline: 3px solid rgba(lightgreen, .2);
  outline-offset: -3px;
  padding: 0 4rem;
  max-width: 140rem;

  @media (max-width: $mobile) {
    padding: 0 1.5rem;
  }

  @media (min-width: 1480px) {
    padding: 0;
  }
}


// Кнопка с градиентом в бордере
// .btn {
//     position: relative;
//     padding: 5rem;
//     border-radius: 50px;
//     border: 2px solid transparent;
//     background: linear-gradient(45deg, red, blue) border-box;
//     -webkit-mask:
//         linear-gradient(#fff 0 0) padding-box,
//         linear-gradient(#fff 0 0);
//     -webkit-mask-composite: xor;
//     mask-composite: exclude;
// }

// .btn__wrap {
//     position: relative;
// }

// .text {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     color: #000;
// }

a {
  color: inherit;
  text-underline-offset: .2em;
}

a,
button {
  @media (any-hover:hover) {
    transition: all .3s;

    &:hover {
      opacity: .6;
    }
  }
}

.btn_blink {
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: all 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: -2rem;
    bottom: -2rem;
    left: -20%;
    width: 2rem;
    transform: rotate(45deg);
    background-color: #fff;
    animation: blink 4s infinite;
  }

  @keyframes blink {
    0% {
      left: -30%;
    }

    30% {
      left: 130%;
    }

    100% {
      left: 130%;
    }
  }
}

// Если в браузере отключен javascript
.no-js {
  & .wrapper::before {
    content: "Пожалуйста, разрешите использовать JavaScript! Без него сайт грустит...";
    position: fixed;
    top: -300px;
    left: 0;
    width: 100%;
    padding: 20px;
    z-index: 99999;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgb(241, 83, 94);
    background-color: rgba(0, 0, 0, 0.75);
    animation-name: real;
    animation-delay: 7s;
    animation-duration: 10s;
    animation-fill-mode: forwards;
  }
}

@keyframes real {
  0% {
    top: -300px;
  }

  10% {
    top: 0;
  }

  90% {
    top: 0;
  }

  100% {
    top: -300px;
  }
}



/* Стиль инпута */
.mask-phone-js {
  transition: all 0.3s;
}

/* Стиль инпута с ошибкой */
.mask-phone-js._error,
._error,
.mask-phone-js._error-no-filled,
._error {
  border: 2px solid rgba(255, 0, 0, 0.9) !important;
}

/* Сообщение об ошибке */
.input-wrap:after {
  content: "Проверьте код оператора или региона — он может начинаться на 3, 4, 5, 6, 8, 9";
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  font-size: 0.8em;
  color: rgba(255, 0, 0, 0.9);
  display: none;
}

.input-wrap.active:after {
  display: block;
}

.input-wrap.no-filled:after {
  display: block;
  content: "Введите номер телефона полностью";
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  font-size: 0.8em;
  color: rgba(255, 0, 0, 0.9);
}