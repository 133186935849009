.gallery {
    padding: 6rem 0 2rem;
}

.gallery__title {
    margin-bottom: 1rem;
    font-size: 5.5rem;
    text-align: center;

    @media (max-width: $tablet) {
        font-size: 3rem;
    }

    @media (max-width: $mintablet) {
        margin-bottom: .5rem;
    }
}

.gallery__desc {
    margin-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
    font-weight: 300;
    line-height: 1;

    @media (max-width: $tablet) {
        font-size: 1.8rem;
    }

    @media (max-width: $mintablet) {
        margin-bottom: 1.4rem;
    }
}


.card,
.gallery .owl-item {
    transition: all 1s ease-out;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

.gallery__owl-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
}

.cards {
    position: relative;
    width: 100%;
    padding: 3rem 0;

    @media (max-width: $mobile) {
        padding: 1rem 0;
    }
}

.card {
    height: 42rem;
    margin: 1em 0;
    border-radius: 3rem;
    opacity: 0.7;
    transform: scale(0.82);
    transition: transform 0.4s 0.5s ease-out, opacity 1s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background-color: transparent;
    overflow: hidden;

    .active & {
        box-shadow: 0 0px 1em rgba(0, 0, 0, 0.2);
        transform: box-shadow 0.3s ease, transform 0.1s 0.4s ease-in, opacity 0.4s ease;
    }

    &__content {
        display: flex;
        align-items: center;
        text-align: center;
        color: #fff;
        opacity: 1;

        .active & {
            opacity: 1;
            transition: opacity 0.4s ease;
        }
    }

    &__title {
        display: inline-block;
        font-size: 2em;
        overflow: hidden;

        span {
            display: inline-block;
            //opacity: 0;
            //transform: translate3d(0,-110%,0);
            //transition: transform 0.4s ease, opacity 0.4s ease;
            animation: slide-up 0.4s 0s ease both;

            .center.active &,
            .center.active.cloned:last-child & {
                opacity: 1;
                animation: slide-down 0.4s 0.4s ease both;
                //transform: translate3d(0,0,0);
                transition: transform 0.3s 0.4s ease, opacity 0.3s ease;
            }
        }
    }

    .center & {
        opacity: 1;
        transform: scale(1);

        &:hover {
            box-shadow: 0 8px 16px -5px rgba(0, 0, 0, 0.4);
        }
    }

    @media (max-width: 768px) {
        height: 28rem;
    }

}

.card__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translate3d(0, -120%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0%, 0);
    }
}


@keyframes slide-up {
    0% {
        opacity: 1;
        transform: translate3d(0, 0%, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -120%, 0);
    }
}