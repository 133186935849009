.hero {
    padding: 2rem 0 4rem;
    background-color: #fff;

    @media (max-width: $mobile) {
        padding: 2rem 0 2.5rem;
    }
}

.hero .owl-carousel {
    position: relative;
    width: calc(100% + 8rem);
    margin-left: -4rem;
    padding: 0 4rem;

    @media (max-width: $mobile) {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        padding: 0 1.5rem;
    }
}

.hero .owl-carousel .owl-stage-outer {
    height: 100%;
    border-radius: 3rem;
    overflow: hidden;

    @media (max-width: $mobile) {
        border-radius: 2rem;
    }
}

.hero-item {
    height: 60rem;

    @media (max-width: $mobile) {
        height: 30rem;
    }
}

.hero__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-image: url("/img/chevron-right.svg") !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    opacity: .5;
    transition: all .3s;

    &:hover {
        opacity: 1;
    }

    & span {
        display: none;
    }

    @media (max-width: $mobile) {
        display: none;
    }
}

.owl-prev {
    left: -.5rem;
    transform: translateY(-50%) rotate(180deg);

    @media (max-width: 1510px) {
        left: .3rem;
    }
}

.owl-next {
    right: -.5rem;
    transform: translateY(-50%);

    @media (max-width: 1510px) {
        right: .3rem;
    }
}